import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { securityService, securityServiceIniting } from '@/services/security/index.js';

const routes = [
  {
    name: 'Home',
    redirect: '/catalog',
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: '/catalog',
        name: 'Catalog',
        meta: { code: 'catalog' },
        component: () => import("@/views/Catalog/CatalogPage.vue"),
      },
      {
        path: '/services',
        name: 'Services',
        meta: { code: 'services' },
        component: () => import("@/views/Services/ServicesPage.vue"),
      },
      {
        path: '/service/:id',
        name: 'Service',
        meta: { code: 'services' },
        component: () => import("@/views/Service/ServicePage.vue"),
      },
      {
        path: '/history',
        name: 'History',
        meta: { code: 'history' },
        component: () => import("@/views/History/HistoryPage.vue"),
      },
      {
        path: '/replenishment',
        name: 'Replenishment',
        meta: { code: 'replenishment' },
        component: () => import("@/views/Replenishment/ReplenishmentPage.vue"),
      },
      {
        path: '/add-card',
        name: 'AddCard',
        meta: { code: 'addCard' },
        component: () => import("@/views/AddCard/AddCardPage.vue"),
      },
      {
        path: '/subscription',
        name: 'Subscription',
        meta: { code: 'subscription' },
        component: () => import("@/views/Subscription/SubscriptionPage.vue"),
      },
      {
        path: '/profile',
        name: 'Subscription',
        meta: { code: 'subscription' },
        component: () => import("@/views/Profile/ProfilePage.vue"),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/catalog',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isGuest = to.matched.some(record => record.meta.isGuest);
  if (!isGuest) {
    if (!securityService.isInited) {
      await securityServiceIniting;
    }
    await securityService.manager.renewToken();
    store.dispatch("auth/setUser", securityService.user);
  }
  next();
});


export default router;
