// import Oidc from 'oidc-client';

export class Manager {
  constructor(manager) {
    this.manager = manager;
  }
  // Renew the token manually
  renewToken() {
    return new Promise((resolve, reject) => {
      this.manager.signinSilent().then((user) => {
        if (user == null) {
          this.signIn(null);
        } else {
          return resolve(user);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Get the user who is logged in
  getUser() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(false);
        } else {
          return resolve(true);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    this.manager.signinRedirect().catch((err) => {
      console.log(err);
    })
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    this.manager.signoutRedirect().then(function(resp) {
      console.log('signed out', resp);
    }).catch((err) => {
      console.log(err);
    })
  }

  // Get the token id
  getIdToken() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user.id_token);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Get the session state
  getSessionState() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user.session_state);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Get the access token of the logged in user
  getAcessToken() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user.access_token);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Takes the scopes of the logged in user
  getScopes() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user.scopes);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }

  // Get the user roles logged in
  getRole() {
    return new Promise((resolve, reject) => {
      this.manager.getUser().then((user) => {
        if (user == null) {
          this.signIn();
          return resolve(null);
        } else {
          return resolve(user.profile.role);
        }
      }).catch((err) => {
        console.log(err);
        this.signIn(null);
        return reject(err);
      });
    })
  }
}
