<template>
  <el-dialog
    v-model="isShow"
    title="Согласие"
  >
    <p>Вы точно хотите добавить услугу?</p>
    <div class="btns">
      <el-button @click="submit">да</el-button>
      <el-button @click="close">нет</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {computed} from "vue";
  import { useStore } from "vuex";

  export default {
    setup() {
      const store = useStore();
      const submit = async ()=>
      {
        await store.dispatch('addService', store.state.payment.idForAgreePopup)
        // await store.dispatch("loadInfo");

        await close();
        location.href = '/services'
      }
      const close = async ()=>
      {
        isShow.value = false;
        store.dispatch('SET_ID_AGREE_POPUP', null)
      }
      const isShow = computed({
        get: () =>
          store.state.layout.isShowAgreeModal,
        set: (status) =>
          store.dispatch("changeModalVisibility", { code: "AgreeModal", status }),
      });
      return {
        isShow,
        submit,
        close
      };
    },
  };
</script>
<style>
  .btns
  {
    display: flex;
    align-items: center;
  }
  .btns button:first-child{margin-right: 10px;}
</style>
