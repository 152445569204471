import { sumFormat, dateFormat } from "@/utils/formatter.js";

export class User {
  constructor(raw = {}) {
    console.log('rawTEst: ', raw);
    this.id = raw.smallyInvestUserId || 0;
    this.fullname = raw.fio || "";
    this.email = raw.email || "";
    this.phone = raw.phone || "";
    this.balance = sumFormat(raw.balance || 0);
    this.frozenBalance = sumFormat(raw.frozenBalance || 0);
    this.image = require("@/assets/img/icons/avatar.svg");
    this.subscriptionExpiresAt = dateFormat(raw.subscriptionExpiresAt) || "";
  }
}
