import { mainApi } from "@/api/main-api.js";
// models
import { Service } from "@/models/Service.js";
// message
import { ElMessage } from "element-plus";

export default {
  state: {
    services: [],
    history:[],
    file: null,
  },
  getters: {
    services(state) {
      return state.services.map(
        (service) => new Service(service)
      );
    }
  },
  mutations: {
    setServices(state, services) {
      state.services = services || [];
    },
    setHistory(state, history) {
      state.history = history || [];
    },
    setFile(state, file) {
      state.file = file || [];
    },
  },
  actions: {
    async loadServices({ commit }) {
      try {
        const response = await mainApi.getServices();
        console.log('json: ',response);
        const getCHeck = await mainApi.getCHeck();
        console.log('getCHeck: ',getCHeck);
        const json = await response.json();
        // const jsonCheck = await getCHeck.json();
        commit("setServices", json.data);
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
        commit("setServices", []);
      }
    },
    async addService({ dispatch }, serviceId) {
      try {
        await mainApi.addService({ serviceId });
        await dispatch("loadServices");
        await dispatch('user/loadInfo')

      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
      }
    },
    async historyService({ commit }, serviceId)
    {
      try {
        const response = await mainApi.history({serviceId});
        const responseJson = await response.json();
        commit("setHistory", responseJson);
        // await dispatch("loadServices");
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
      }
    },
    async uploadFile({ commit }, data)
    {
      console.log('data: ',data);
      try {
        const response = await mainApi.uploadFile({...data});
        const responseJson = await response.json();
        commit("setFile", responseJson);
        // await dispatch("loadServices");
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
      }
    }
  },
};
