import { BaseApi } from "./base-api.js";
// helpers
import { composeUriQuery } from "@/utils/uri-query.js";

class PaymentApi extends BaseApi {
  getHistory(params = {}) {
    return fetch(`${this.baseUrl}/History/List?${composeUriQuery(params)}`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  getHistoryFilters() {
    return fetch(`${this.baseUrl}/RobokassaPayment/GetPaymentFilterTypes`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  getDealStatusTypes() {
    return fetch(`${this.baseUrl}/RobokassaPayment/GetDealStatusTypes`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  createPayment(inform)
  {
    return fetch(`${this.baseUrl}/RobokassaPayment/CreatePayment?${new URLSearchParams({...inform})}`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  getPaymentFilterType(data)
  {
    return fetch(`${this.baseUrl}/RobokassaPayment/GetPaymentFilterTypes?${new URLSearchParams({...data})}`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  getsubscriptionTypes()
  {
    return fetch(`${this.baseUrl}/Subscription/SubscriptionTypes`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  renewSubscription(data)
  {
    return fetch(`${this.baseUrl}/Subscription/RenewSubscription`, {
      ...this.getDefaultOptions(),
      method: "POST",
      body: JSON.stringify(data)
    });
  }
}

export const paymentApi = new PaymentApi();
