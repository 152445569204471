import Oidc from 'oidc-client';
import { Manager } from "./manager.js";
// import { openidApi } from "@/api/openid-api.js";
Oidc.Log.logger = console;
class SecurityService {
  constructor() {
    this.isInited = false;
    this.oidcManager = null;
    this.manager = null;
    this.config = null;
    this.settings = null;
    this.user = null;
  }

  async init() {
    await this.getIdentityConfig();
    this.getSettings();
    this.createUserManager();
    await this.manager.getSignedIn();
    this.user = await this.manager.getUser();
    this.isInited = true;
  }

  createUserManager() {
    this.oidcManager = new Oidc.UserManager(this.settings);
    this.manager = new Manager(this.oidcManager);
  }

  getSettings() {
    this.settings = {
      userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
      authority: this.config.authority,
      client_id: "smally_Expert.Web.js_id",
      response_type: "code",
      scope: "openid profile smally_Expert.Api",
      redirect_uri: `${this.config.clientUrl}/callback.html`,
      silent_redirect_uri: `${this.config.clientUrl}/refresh.html`,
      post_logout_redirect_uri: `${this.config.clientUrl}/index.html`,
      automaticSilentRenew: true
    };
    // console.log('settings: ',this.settings);
  }

  async getIdentityConfig() {
    // const response = await openidApi.getIdentityConfig();
    // this.config = await response.json();
    this.config ={
      // authority: " https://auth.smally.me",
      // clientUrl: "https://expert.smally.me",
      // apiUrl: "https://expert.smally.me/api"


      authority: "https://authtest.smally.me",
      // clientUrl: "https://localhost:8081",
      clientUrl: "https://experttest.smally.me",
      apiUrl: "https://experttest.smally.me"
    };
  }
}

const securityService = new SecurityService();
const securityServiceIniting = securityService.init();

export {
  securityService,
  securityServiceIniting,
};
