import { paymentApi } from "@/api/payment-api.js";
// models
import { Payment } from "@/models/Payment.js";
import { ServiceStatus } from "@/models/ServiceStatus.js";
// message
import { ElMessage } from "element-plus";

export default {
  state: {
    history: [],
    filters: [],
    activeFilterIndex: 0,
    filterDates: [],
    paymentLink: null,
    paymentFilterType: null,
    idForAgreePopup: null,
  },
  getters: {
    history(state) {
      return state.history.map((payment) =>
        new Payment(payment)
      );
    },
    historyFilters(state) {
      state.filters.forEach(
        (filter) => {
          if (/квартал|вчера/i.test(filter.name)) {
            filter.isDisabled = true;
          }
          if (/интервал/i.test(filter.name)) {
            filter.isInterval = true;
          }
        }
      );
      return state.filters;
    },
    selectedHistoryFilter(state) {
      return state.filters[state.activeFilterIndex] || null;
    },
  },
  mutations: {
    setHistory(state, history) {
      state.history = history || [];
    },
    setFilters(state, filters) {
      state.filters = filters || [];
    },
    selectFilter(state, { index, dates = [] }) {
      state.activeFilterIndex = state.filters[index]
        ? index
        : 0;
      state.filterDates = dates || [];
    },
    setPaymentLink(state,paymentLink)
    {
      state.paymentLink = paymentLink
    },
    setPaymentFilterType(state,paymentFilterType)
    {
      state.paymentFilterType = paymentFilterType
    },
    setIdForAgreePopup(state,idForAgreePopup)
    {
      state.idForAgreePopup = idForAgreePopup
    },
  },
  actions: {
    async loadHistory({ state, getters, commit }) {
      try {
        const filters = getters.selectedHistoryFilter
          ? {
            paymentFilterType: getters.selectedHistoryFilter.paymentFilterType,
            startTime: state.filterDates[0] || undefined,
            endTime: state.filterDates[1] || undefined,
          }
          : {};
        const response = await paymentApi.getHistory(filters);
        const json = await response.json();
        commit("setHistory", json.data);
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
        commit("setHistory", []);
      }
    },
    async loadHistoryFilters({ commit }) {
      try {
        const response = await paymentApi.getHistoryFilters();
        const json = await response.json();
        commit("setFilters", json.data);
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
        commit("setFilters", []);
      }
    },
    async selectFilter({ commit }, { index, dates }) {
      commit("selectFilter", { index, dates });
    },
    async loadDealStatusTypes({ commit }) {
      try {
        const response = await paymentApi.getDealStatusTypes();
        const json = await response.json();
        ServiceStatus.init(json.data);
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
        commit("setDealStatusTypes", []);
      }
    },
    async CREATE_PAYMENT({commit, dispatch},data)
    {
      try
      {
        const response = await paymentApi.createPayment(data);
        const json = await response.json();
        commit("setPaymentLink", json);
        dispatch('user/loadInfo')
        if(json.success)
          window.location.href = json.data
      }
      catch(e)
      {
        ElMessage.error(
        {
          message: e,
          duration: 2500,
        });
      }
    },
    SET_ID_AGREE_POPUP({commit},data)
    {
      try
      {
        commit("setIdForAgreePopup", data);
      }
      catch(e)
      {
        console.log('err', e);
      }
    },
  },
};
