export function numberFormat(number = 0, digits = 0) {
  number = +number || 0;
  const resultStr = `${!digits ? Math.floor(number) : number.toFixed(digits)}`;
  return resultStr.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g,"$1 ");
}

export function sumFormat(sum) {
  return `${numberFormat(sum)} ₽`;
}

export function percentFormat(percent) {
  return `${numberFormat(percent, 2)} %`;
}

export function dateFormat(date = "", withTime = false) {
  if (!date) return "";
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) return "";
  const [day, month, year, hour, minute] = [
    String(dateObj.getDate()).padStart(2, 0),
    String(dateObj.getMonth() + 1).padStart(2, 0),
    dateObj.getFullYear(),
    String(dateObj.getHours()).padStart(2, 0),
    String(dateObj.getMinutes()).padStart(2, 0),
  ];
  return withTime
    ? `${day}.${month}.${year} в ${hour}:${minute}`
    : `${day}.${month}.${year}`;
}

export function dateTimeFormat(date = "") {
  return dateFormat(date, true);
}

function wordFormat(value, one, manyForm, oneForm) {
  const word = value % 10 === 1 ? one
    : value >= 11 && value <= 19 ? manyForm
      : value % 10 >= 2 && value % 10 <= 4 ? oneForm
        : manyForm;
  return `${value} ${word}`;
}

export function daysFormat(day) {
  return wordFormat(day, "день", "дней", "дня");
}

export function ageFormat(age) {
  return wordFormat(age, "год", "лет", "года");
}
