export function parseUriQuery(query = '') {
  try {
    const result = query
      .split('&')
      .map((item) => item.split('='))
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [decodeURIComponent(key)]: decodeURIComponent(value),
        }),
        {}
      );
    return result;
  } catch (e) {
    console.error(`Unable to parse query: ${query}`, e);
    return {};
  }
}

export function composeUriQuery(query = {}) {
  return Object.entries(query)
    .filter((entry) => entry[1] !== undefined)
    .map(([key, value]) => {
      if (!Array.isArray(value))
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

      return value
        .map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
        .join('&');
    })
    .join('&');
}
