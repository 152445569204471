export class ServiceStatus {
  static initial = [];

  static init(statuses) {
    this.initial = statuses || [];
  }

  static getInitial(id) {
    return this.initial.find(
      (status) => status.dealStatus === id
    ) || {};
  }

  constructor(id = -1) {
    this.id = id;
    const initial = this.constructor.getInitial(this.id);
    this.title = initial.name || "_";
    this.color = initial.colorInHex || "#ffffff";
  }
}
