import { mainApi } from "@/api/main-api.js";
// models
import { PersonalService } from "@/models/PersonalService.js";
import { User } from "@/models/User.js";

export default {
  namespaced: true,
  state: {
    services: [],
    info: {},
  },
  getters: {
    services(state) {
      return state.services.map(
        (service) => new PersonalService(service)
      );
    },
    info(state) {
      // console.log('info: ', state);
      return new User(state.info.data);
    },
  },
  mutations: {
    setServices(state, services) {
      state.services = services || [];
    },
    setInfo(state, info) {
      console.log('setInfo: ',info);
      state.info = info || {};
    },
  },
  actions: {
    async load({ dispatch }) {
      await dispatch("loadInfo");
      await dispatch("loadMyServices");
    },
    async loadInfo({ commit }) {
      try {
        console.log('loadInfo');
        const response = await mainApi.getUser();
        const json = await response.json();
        commit("setInfo", json);
      } catch(e) {
        console.log(e);
      }
    },
    async loadMyServices({ commit }) {
      try {
        const response = await mainApi.getUserServices();
        const json = await response.json();

        commit("setServices", json.data);
      } catch(e) {
        console.log(e);
        commit("setServices", []);
      }
    },
  },
};
