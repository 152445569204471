import { sumFormat } from "@/utils/formatter.js";

export class Service {
  constructor(raw = {}) {
    this.id = raw.id || 0;
    this.name = raw.name || "";
    this.price = sumFormat(raw.price || 0);
    this.description = raw.description || "_";
  }
}
