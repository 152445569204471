import { paymentApi } from "@/api/payment-api.js";
// import { mainApi } from "@/api/main-api.js";
// models
// import { Payment } from "@/models/Payment.js";
// import { ServiceStatus } from "@/models/ServiceStatus.js";
// message
import { ElMessage } from "element-plus";

export default {
  state: {
    idForSubscribePopup: null,
    subs: null,
    sibscribeInfo: null,
    errMsg: ''
  },
  mutations: {
    setSubscribe(state,subs)
    {
      console.log('subs: ',subs);
      state.subs = subs;
      console.log('state.subs: ',state.subs);
    },
    setSubscribeInfo(state,sibscribeInfo)
    {
      state.sibscribeInfo = sibscribeInfo;
    },
    setErrorInfo(state,errMsg)
    {
      state.errMsg = errMsg;
    }
  },
  // Subscription/RenewSubscription
  actions: {
    async SET_SUBSCRIBE({commit, dispatch},data)
    {
      try {
        const response = await paymentApi.renewSubscription(data);
        const json = await response.json();
        await dispatch('user/loadInfo')
        commit("setSubscribe", json);
      } catch(e) {
        commit('setErrorInfo', e);
        ElMessage.error({
          message: e,
          duration: 2500,
        });
      }
    },
    async GET_SUBSCRIBE_INFO({commit})
    {
      try {
        const response = await paymentApi.getsubscriptionTypes();
        const json = await response.json();
        commit("setSubscribeInfo", json.data);
      } catch(e) {
        ElMessage.error({
          message: e,
          duration: 2500,
        });
      }
    },
  },
};
