<template>
  <router-view/>
  <replenishment-modal />
  <agree-modal />
  <subscribe-upgrade />
</template>

<script>
  // import { useStore } from "vuex";
  // modals
  import ReplenishmentModal from "@/components/Modal/ReplenishmentModal.vue";
  import AgreeModal from "@/components/Modal/AgreeModal.vue";
  import  SubscribeUpgrade from "@/components/Modal/SubscribeUpgrade.vue";

  export default {
    components: {
      ReplenishmentModal,
      AgreeModal,
      SubscribeUpgrade
    },
    setup() {
      // const store = useStore();
      // store.dispatch("user/load");
    },
  };
</script>
