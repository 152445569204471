<template>
  <el-dialog
    v-model="isShow"
    title="Продление подписки"
  >
    <p>Вы точно хотите продлить подписку?</p>
    <div class="btns" :class="{mb: consentToUpgrade}">
      <el-button @click="submit">да</el-button>
      <el-button @click="close">нет</el-button>
    </div>
    <el-select v-if="consentToUpgrade"
      v-model="value"
      class="subscription__select m-2"
      placeholder="На какой срок продлить"
      size="large">
        <el-option
            v-for="(item, index) in sibscribeInfo"
            :key="index"
            :value="item.subscriptionType"
            @click = "upgrade(item.subscriptionType)"
            class="subscription__select-li"
          >
          <span class="type">{{item.subscriptionType}} {{months[index]}}</span>
          <span class="val">{{item.price.format()}} Р</span>
        </el-option>
    </el-select>
    <div class="err" v-if = "sibscribeInfoError">
      {{sibscribeInfoError}}
    </div>
  </el-dialog>
</template>
<script>
  import { computed,ref } from "vue";
  import { useStore } from "vuex";

  export default {
    setup() {

      function moneyFormat() {
        let str = String(this)
        let num = str .replace(/\s/g, '');
        // return parseFloat(str).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
        return (parseInt(num)).toLocaleString('ru-Ru');
      }
      Number.prototype.format = moneyFormat;

      const store = useStore();
      const value = ref('');
      const consentToUpgrade = ref(false)
      const sibscribeInfo = computed(() => store.state.subscribe.sibscribeInfo);
      const sibscribeInfoError = computed(() => store.state.subscribe.errMsg);
      const months = ref(
      [
        'Месяц',
        'Месяца',
        'Месяцев'
      ])
      const submit = async ()=>
      {
        consentToUpgrade.value = true;
      }
      const upgrade = async (value)=>
      {
        await store.dispatch('SET_SUBSCRIBE', {subscriptionType: value})
        await close();
      }
      const close = async ()=>
      {
        isShow.value = false;
      }
      const isShow = computed({
        get: () =>
          store.state.layout.isShowSubscribeUpgrade,
        set: (status) =>
          store.dispatch("changeModalVisibility", { code: "SubscribeUpgrade", status }),
      });
      return {
        isShow,
        submit,
        close,
        sibscribeInfo,
        value,
        consentToUpgrade,
        upgrade,
        sibscribeInfoError,
        months
      };
    },
  };
</script>
<style>
  .btns
  {
    display: flex;
    align-items: center;
  }
  .mb{margin-bottom: 20px;}
  .subscription__select
  {
    max-width: 210px;
  }
  .btns button:first-child{margin-right: 10px;}
  .subscription__select-li
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
