import { authApi } from "@/api/auth-api.js";
import { setCookie } from "@/utils/cookies.js";

export default {
  namespaced: true,
  actions: {
    async authenticate() {
      try {
        const response = await authApi.authenticate({
          login: "79777538542",
          password: "test55555J1J",
          role: "string",
        });
        const json = await response.json();
        setCookie("jwtToken", json.jwtToken);
        window.location.reload();
      } catch(e) {
        console.log(e);
      }
    },
    setUser(store, user) {
      setCookie("accessToken", user.access_token);
      setCookie("refreshToken", user.refresh_token);
    },
  },
};
