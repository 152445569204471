import { sumFormat, dateTimeFormat } from "@/utils/formatter.js";

export class Payment {
  constructor(raw = {}) {
    this.date = dateTimeFormat(raw.createdAt);
    this.isReplenishment = raw.isReplenishment || false;
    this.sum = sumFormat(raw.sum);
    this.serviceId = raw.serviceId;
    this.name = raw.text;
  }
}
