<template>
  <el-form
    ref="formRef"
    :model="form"
    :rules="rules"
    class="replenishment-form"
  >
    <div class="replenishment-form__title">
      Введите сумму для пополнения
    </div>
    <el-form-item>
      <el-input
        v-model="form.sum"
        type="number"
        min="100"
        step="1"
        class="white padding-big border-radius"
      ></el-input>
      <div class="replenishment-form__tip">
        *Минимальная сумма для пополнения баланса 100 ₽
      </div>
    </el-form-item>
    <el-form-item>
      <el-button @click="submit" class="replenishment-form__button">Пополнить</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { ref, reactive } from "vue";
  import { useStore } from "vuex";

  export default {
    setup() {
      const store = useStore();
      const formRef = ref(null);
      const form = reactive({
        sum: null,
        isSubscriptionNeeded:false,
      });
      const rules = reactive({
        sum: {
          required: true,
          message: "Обязательное поле",
        },
      });

      const submit = async () => {
        await store.dispatch('CREATE_PAYMENT', form)
        // console.log("submit", formRef);
      };
      return {
        formRef,
        form,
        rules,

        submit,
      };
    },
  };
</script>

<style lang="scss">
  @import "_replenishment-form.scss";
</style>
