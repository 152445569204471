import { BaseApi } from "./base-api.js";
// helpers
import { composeUriQuery } from "@/utils/uri-query.js";
// import { toFormData } from '@/helpers/formatter.js';

class MainApi extends BaseApi {
  getUser() {
    return fetch(`${this.baseUrl}/ExpertUsers/Read`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  getServices() {
    return fetch(`${this.baseUrl}/ExpertServices/List`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }
////
  getCHeck() {
    return fetch(`${this.baseUrl}/test/check`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }
////
  getUserServices() {
    return fetch(`${this.baseUrl}/ExpertServices/UserServicesList`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }

  addService(params = { serviceId: 0 }) {
    return fetch(`${this.baseUrl}/ExpertServices/AddUserExpertService?${composeUriQuery(params)}`, {
      ...this.getDefaultOptions(),
      method: "POST",
    });
  }
  history(params = { serviceId: 0 }) {
    return fetch(`${this.baseUrl}/History/ServiceOperations?${composeUriQuery(params)}`, {
      ...this.getDefaultOptions(),
      method: "GET",
    });
  }
  uploadFile(data) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('UserServiceId', data.UserServiceId);
    return fetch(`${this.baseUrl}/ExpertServices/UploadServiceFile`, {
      ...this.getDefaultOptions({
        'Content-Type': undefined,
      }),
      method: "POST",
      body: formData,
    });
  }
}

export const mainApi = new MainApi();
