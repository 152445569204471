import { sumFormat, dateFormat } from "@/utils/formatter.js";
import { ServiceStatus } from "./ServiceStatus.js";

export class PersonalService {
  constructor(raw = {}) {
    this.id = raw.id || 0;
    this.date = dateFormat(raw.createdAt) || "_";
    this.name = raw.name || "";
    this.price = sumFormat(raw.price || 0);
    this.description = raw.description || "_";
    this.status = new ServiceStatus(raw.dealStatus);
  }
}
