import { TABLET_PORTRAIT_UPPER_BOUNDARY } from "@/utils/constants/layout.js";

export default {
  state: {
    isShowSidebar: false,
    windowWidth: 0,
    // modals
    isShowReplenishmentModal: false,
    isShowAgreeModal: false,
    isShowSubscribeUpgrade: false,
  },
  getters: {
    isShowSidebar(state) {
      return state.windowWidth > TABLET_PORTRAIT_UPPER_BOUNDARY - 1
        ? true
        : state.isShowSidebar;
    },
  },
  mutations: {
    updateWindowWidth(state, width) {
      state.windowWidth = width;
    },
    toggleSidebar(state) {
      state.isShowSidebar = !state.isShowSidebar;
    },
    changeModalVisibility(state, { code, status }) {
      if (typeof state[`isShow${code}`] === "undefined") return;
      state[`isShow${code}`] = status;
    },
  },
  actions: {
    updateWindowWidth({ commit }, width) {
      commit("updateWindowWidth", width);
    },
    toggleSidebar({ commit }) {
      commit("toggleSidebar");
    },
    changeModalVisibility({ commit }, { code, status }) {
      commit("changeModalVisibility", { code, status });
    },
  },
};
