import { BaseApi } from "./base-api.js";

class AuthApi extends BaseApi {
  authenticate(data) {
    return fetch(`${this.baseUrl}/Login/authenticate`, {
      ...this.getDefaultOptions(),
      method: "POST",
      body: JSON.stringify(data),
    });
  }
}

export const authApi = new AuthApi();
