<template>
  <el-dialog
    v-model="isShow"
    title="Пополнение счета"
    class="replenishment-modal"
  >
    <replenishment-form></replenishment-form>
  </el-dialog>
</template>

<script>
  import { computed } from "vue";
  import { useStore } from "vuex";
  // components
  import ReplenishmentForm from "@/components/Form/ReplenishmentForm.vue";

  export default {
    components: {
      ReplenishmentForm,
    },

    props: {
    },
    setup() {
      const store = useStore();
      const isShow = computed({
        get: () =>
          store.state.layout.isShowReplenishmentModal,
        set: (status) =>
          store.dispatch("changeModalVisibility", { code: "ReplenishmentModal", status }),
      });
      return {
        isShow,
      };
    },
  };
</script>
