import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import "@/assets/styles/_global.scss";
// plugins
import { ElementPlus } from "@/plugins/element-plus";
import InlineSvg from "vue-inline-svg";
import "@/plugins/optimized-resize.js";

const app = createApp(App);

app.use(router);
app.use(store);

app.use(ElementPlus);
app.component("inline-svg", InlineSvg);

const vm = app.mount("#app");
store._vm = vm;
