import { createStore } from 'vuex';
// modules
import user from './user.js';
import auth from './auth.js';
import services from './services.js';
import payment from './payment.js';
import layout from './layout.js';
import subscribe from './subscribe.js';

export default createStore({
  modules: {
    user,
    auth,
    services,
    payment,
    layout,
    subscribe
  },
});
