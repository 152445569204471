import "@/plugins/fetch-interceptor.js";
import { getCookie } from "@/utils/cookies.js";

export class BaseApi {
  baseUrl = process.env.VUE_APP_API_URL;

  constructor() {}

  getDefaultOptions(additionalHeaders = {})
  {
    const headers = {};
    Object.entries({
      "Content-Type": "application/json",
      "Cache": "no-cache",
      ...additionalHeaders,
    }).map(([header, value]) => {
      if (typeof value !== "undefined") {
        headers[header] = value;
      }
    });
    if (this.getToken()) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }
    return {
      mode: "cors",
      credentials: "include",
      referrerPolicy: "no-referrer",
      headers,
    };
  }

  getToken()
  {
    return getCookie("accessToken");
  }
}
