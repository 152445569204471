const { fetch: originalFetch } = window;

window.fetch = function(...args) {
  const [resource, config] = args;
  return originalFetch(resource, config)
    .then(async (response) => {
      if (response.status === 400) {
        const json = await response.json();
        throw json.errors.join("; \n");
      }
      return response;
    });
};
